import Qs from 'qs';
import axios from "axios";
import utils from './jh.core.js';
const config = require('./config.js');
//创建axios实例
const axiosInst = axios.create({
    timeout: 1*60*60*1000//一小时超时
});
axiosInst.interceptors.request.use(
    reqConfig => {
        reqConfig.headers["encryptData"] = utils.$$tools.getPropFromVuex('encryptData');
        const userToken = sessionStorage.getItem(utils.$$const.baseVar.jfAccessToken);
        if(userToken){
            reqConfig.headers[utils.$$const.baseVar.jfAccessToken] = userToken;
        }
        return reqConfig;
    },
    error => {
        return Promise.error(error);
    }
);
axiosInst.interceptors.response.use(
    res => {
        const invalidCode=[utils.$$const.sysCode._0001,utils.$$const.sysCode._0002,utils.$$const.sysCode._0009,utils.$$const.sysCode._0010
            ,utils.$$const.sysCode._0014,utils.$$const.sysCode._0015]
        if(res.headers[utils.$$const.baseVar.errorCode.toLowerCase()]==utils.$$const.sysCode._0000 && res.headers[utils.$$const.baseVar.jfAccessToken.toLowerCase()]){
            sessionStorage.setItem(utils.$$const.baseVar.jfAccessToken, res.headers[utils.$$const.baseVar.jfAccessToken.toLowerCase()]);
        }else if(invalidCode.includes(res.headers[utils.$$const.baseVar.errorCode.toLowerCase()])){
            sessionStorage.removeItem(utils.$$const.baseVar.jfAccessToken);
            sessionStorage.removeItem("userInfo");
            location.reload();
        }
        return Promise.resolve(res);
    },
    err => {
        console.log(err);
        return Promise.reject(err);
    }
);
export const $$post = function (options) {
    return new Promise((resolve, reject) => {
        let url=options.url;
        if(options.urlPre){
            url=options.urlPre + options.url;
        }else{
            if(config.contextPath)url=config.contextPath +url;
            url=config.baseURL+url;
        }
        let params = JSON.stringify(options.params);
        if(utils.$$tools.getPropFromVuex('encryptData'))params = utils.$$str.encrypt(params);//如果要加密传输，需要把参数进行加密处理
        axiosInst({
            method: "post",
            url: url,
            transformRequest:[
                function (data) {
                    return Qs.stringify(data)
                }
            ],
            data: { data: params}
        }).then(res => {
            if(res.data){
                if(utils.$$tools.getPropFromVuex('encryptData'))res.data=JSON.parse(utils.$$str.decrypt(res.data));
                if(res.headers[utils.$$const.baseVar.errorCode.toLowerCase()]==utils.$$const.sysCode._0000){//必须是拦截器返回成功才处理其它
                    if (!res.data.result) {
                        utils.$$tools.error({ message: res.data.msg });
                    }
                    resolve(res.data);
                }
            }
        }).catch(err => {
            utils.$$tools.error({ message: err });
            reject(err)
        });
    });
};
//文件上传
export const $$upload = function (options) {
    return new Promise((resolve, reject) => {
        let url=options.url;
        if(!url.startsWith("/"))url="/"+url;
        if(options.urlPre){
            url=options.urlPre + options.url;
        }else{
            if(config.contextPath)url=config.contextPath +url;
            url=config.baseURL+url;
        }
        axiosInst.post(url,options.formData).then(async (res)=> {
            if(utils.$$tools.getPropFromVuex('encryptData')){
                res.data=JSON.parse(utils.$$str.decrypt(res.data));
            }
            resolve(res.data);
        }).catch(err => {
            utils.$$tools.error({ message: err });
        });
    });

}
export const $$downLoad = function (options) {
    return new Promise((resolve, reject) => {
        const aloneServerInfo=utils.$$str.decrypt(sessionStorage.getItem("aloneServerInfo"));
        let url= aloneServerInfo+ options.url;
        if(options.cluserServer && options.cluserServer==1){
            if(config.contextPath)url=config.contextPath+options.url;
            url=config.baseURL+url;
        }
        let params = JSON.stringify(options.params);
        if(utils.$$tools.getPropFromVuex('encryptData'))params = utils.$$str.encrypt(params);//如果要加密传输，需要把参数进行加密处理
        axiosInst({
            url: url,
            method: 'post',
            responseType: 'blob',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            transformRequest:[
                function (data) {
                    return Qs.stringify(data)
                }
            ],
            data: { data: params}
        }).then(res => {
            if(utils.$$const.sysCode._0000==res.headers[utils.$$const.baseVar.downloadcode.toLowerCase()]){
                const blob = res.data
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onload = (e) => {
                    const a = document.createElement('a');
                    a.download = options.params.fileName;
                    a.href = e.target.result;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            }else{
                utils.$$tools.error({message:'附件丢失了'});
            }
            resolve(res);
        }).catch(err => {
            utils.$$tools.error({message:err});
        });
    });
};



